import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "759px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2dc18af5fe46aa3a6b0f8d0cce8f1896/ef3e1/ff16-title.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.123552123552116%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACbUlEQVQozwFiAp39ABMFAiUIAx8FASMFAiAHBRUHBSIWFTIlJCwwMiUoKxgbHSEeISQfIiosLj4+Pjw9PDo6OTIyLyopJzAvLgA2EgVJFwotCAM4BQJRDQY6DgsyDgtjRURgam4kKSoLDxFPRktXSUtpbG6Ojo2GhoZ/f31qZ2RYVFFXU1AAMREBHAUAGQAAQwcCXgwFNwsJV0lJpKWljpicOjQ2LyMgU0JFgGNjkpiYzs3JwL65tLKutbKtq6eibmhmACkXC0o2L0U5MyIFAS8EASIAAHp5euDm52FpbCEZHCAcHCMZGlc2NXl4dbayrsbCvMrIwp+jnldVUTMhHABNUE+Xk5CWi4RfVU5iQ0BdREOQkZGnrK5UVllQRktLRkswMDFdQj1ZTUuWlJSfnZiKfHdjKCIwBwUnFBEAQD4/YF5cZ2Rign9+rqupsLKyx8fHhYiIbm1unoSGjW5yZGNibWBbUU1OpqWlYGBfKQoLixcLmi4XRhIKADw8PkhGRnRycp2bmpGRjXp3dHZ2dmtra1JSUlBFR0o9QDc6OTs3Mkw9O3Rvcnt8eVFHRUkYD5tLMYgmFgA1NTc9PD1wbWyEgHxnYVtCQD0sLCxEQkA2LzAIDhAJEBELDg4fFRA9KCU0JygqLSwvISAjCAQcBAE1Eg0AIyQmJygqKSosOTg3SkdFLC0tKiwsLSgmJB8hDBASCAwNFBYZFhQTGhMRJxkXAwYEHxEPIAkGFQgGFgwJABQXGhgcHh8hIyUmJzIyMSkqKiYlJSchHycoKQsPEhIUFA8TFR8hIw4ODBANCwQJBw0KBw8HBRcHByMHBpfboA4q9KbVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Final Fantasy 16 Title",
            "title": "Final Fantasy 16 Title",
            "src": "/static/2dc18af5fe46aa3a6b0f8d0cce8f1896/ef3e1/ff16-title.png",
            "srcSet": ["/static/2dc18af5fe46aa3a6b0f8d0cce8f1896/a2ead/ff16-title.png 259w", "/static/2dc18af5fe46aa3a6b0f8d0cce8f1896/6b9fd/ff16-title.png 518w", "/static/2dc18af5fe46aa3a6b0f8d0cce8f1896/ef3e1/ff16-title.png 759w"],
            "sizes": "(max-width: 759px) 100vw, 759px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Man, where to even start here. Final Fantasy 16 is a great video game brought low by some terrible design choices.`}</p>
    <p>{`First, I'd like to start by thanking YoshiP and his team for delivering a world and a story finally worthy of the series' storied legacy. For the first time in a long while, maybe since Final Fantasy X the story remained interesting `}<em parentName="p">{`AND`}</em>{` coherent for the entire runtime. Unlike 15 or 13 or 8, no one prattles on about meaningless esoteric motivations or wild tangents that are never touched on again. The "threads of consciousness" thing comes close, but when the villain eventually calls it free will, you can forgive it. Clive and his cast of merry men and women are all well written, acted, and fleshed out characters. The focus remains firmly on Clive, Jill and Joshua and consequently we get a great deal of backstory and emotional payoffs the whole 50+ hours it takes to see the game through to the end. The villain is interesting and menacing, even if his motivations are exceedingly basic. Valisthea is a well thought out world full of interesting `}<em parentName="p">{`looking`}</em>{` locations, with a great deal of lore to be found via the on screen, mid cutscene lore manual. Each region is distinct in it's peoples and architecture and I could see more than one spin-off taking place within this world to fill in even more of the backstory. A Tactics "sequel" would make a lot of sense here, as Valisthea is a world at war and is rife with the political intrigue that FFT and, more recently, Triangle Strategy have utilized to great effect.`}</p>
    <p>{`The combat system, designed by Ryota Suzuki of Devil May Cry fame, is fast, frenetic, and very satisfying `}<em parentName="p">{`most`}</em>{` of the time. By the end of the game you'll be able to mix and match abilities from seven different Eikons, three of which don't go on cooldown and six that will. The abilities on cooldown are all flashy and hard hitting, inflicting a mixture of HP and stagger damage. Once an enemy is fully staggered you can unleash a massive barrage of abilities, magic, and sword strikes. You can enter limit break by going semi-prime to regain health, move faster, and deal more damage ala Kratos' Spartan Rage. No discussion of combat would be complete without the highlight of this game, the Eikon battles. Clive and his opponent transform into giant summons pulled from across the Final Fantasy mythos and duke it out. These battles are pure spectacle and nothing else and are an incredible sight to behold. The standout, for me, is the battle against Titan and it puts to shame anything God of War has ever thrown at us.`}</p>
    <p>{`Okay, so that's what FF16 did well. Now let's get to what brings this game down from an easy GotY winner to a middling AAA title.`}</p>
    <h3>{`Cutscenes`}</h3>
    <p>{`This is `}<em parentName="p">{`NOT`}</em>{` and exaggeration, 80% of your playtime in this game will be watching cutscenes. They are dense, they are legion, and after a while they drag. There are so many in the game it compares unfavorably to Xenosaga Episode 1, a game which famously began with you hitting the start button and then watching 45 minutes of cutscenes before you got control of your character. There are vast swathes of this game that are like that and the only saving grace is that the story remains interesting throughout. There are just massive pacing problems between the story presentation and the massively enjoyable combat or Eikon battles.`}</p>
    <h3>{`Combat`}</h3>
    <p>{`Speaking of combat...yes, it's very fun. It's also very, very easy. In my playthrough I died exactly twice, both times to attacks I had no idea would deal large amounts of continuous damage. I've done every hunt in the game, no monster there feels like a worthy endgame monster. Svarog, the giant level 50 red dragon? Took him down using only one potion and one high potion. Behemoth King came the closest, but still did not exhaust my healing supplies. Combat itself also really lacks any nuance. There are no status effects, no elemental strengths and weaknesses, and no boss has gimmick to exploit or required to kill it. The closest thing to a gimmick I saw would be the two or three bosses who could attack quickly enough for me not to want to counterattack after performing a precision dodge. The Eikon battles could be considered a gimmick, but they are all so easy that they too are their own variety of playable cutscene. By about 60% of the way through the game, almost every fight can be completed easily. I'd walk into whatever combat arena the quest pushed me into and just fire off Flames of Rebirth and everything is dead, wave two would come in Aerial Blast would come out and I'd stand there while things slowly die and then pick them off. The elite would come in and maybe then I'd take some damage.`}</p>
    <h3>{`Quests`}</h3>
    <p>{`There's just no getting around it, the sidequests in this game are utter trash. They amount to little more than talk to these two or three people or go here and kill these monsters. In most cases, you can fast travel close to the target, kill and/or talk, and fast travel back and you're done. Thank you very much, here's twenty renown and useless gold or a small amount of xp. But it's not just the sidequests affected by this issue, because outside of the big main questline scenarios (which we'll get to next), the individual quests along the way are designed exactly the same. For example, early on when you get to Martha's Rest, you have a mainline quest to go down two ladders and kill three scorpions to rescue a carpenter to fix a bridge. Shortly after, I believe once you get through Eastpool, a sidequest opens up in Martha's Rest where you have to climb down the same two ladders and kill three crabs in the exact same location. It certainly doesn't help that the stories behind these quests are mostly uninspired. Towards the end, as you're wrapping up the remaining sidequests before the final fight, they do start to carry some weight, but the tasks required to complete them do not differ from any other quests.`}</p>
    <p>{`Now for the big quests. Without fail these all drop you into lengthy corridors that would make the level designers of Final Fantasy XIII blush. These all eventually lead to slightly larger rooms that are always combat encounters. There are no dungeons to speak of, there are no puzzles. Hell, there's no backtracking. It's just a beeline for the boss every single time. Thank goodness the boss battles in this game do not disappoint.`}</p>
    <h3>{`Exploration`}</h3>
    <p>{`I've mentioned the corridors and combat arenas of the big story quests, but it doesn't get much better in the "open" areas in between story areas. While there are certainly some points of interest to head towards, there is invariably `}<em parentName="p">{`nothing`}</em>{` there. Fear not, if an area looks interesting I guarantee that some sidequest or hunt will get you there eventually. You get chocobos in this game, but there's no point. None of the wide open areas are large enough to require a chocobo to get across efficiently.`}</p>
    <h3>{`RPG Mechanics`}</h3>
    <p>{`Since this game is first and foremost a spin-off from Devil May Cry, what is present here feels like we're paying lip service to the series roots. Yes, there are levels and experience. No, it never feels impactful when you do level up. Enemies drop gold, but I'm not really sure why. After getting the powers of your second or third Eikon, you won't be buying any weapons or armor that are upgrades, and outside of purchasing songs for the jukebox and potions, there's no other use for it. I ended the game with like 400,000 gil because there was nothing to use it on, which makes the numerous random pick ups of 10 gil while exploring feel even more tacked on. Upgrades you craft for your little bits of armor add like 5 HP and 8 defense, which is a drop in the water of your overall stat, reducing the excitement of crafting any of it.`}</p>
    <h3>{`Final Thoughts`}</h3>
    <p>{`Despite all the negatives I've said above, I quite enjoyed my time with this game. The story, characters, and combat were enough to carry me through the numerous rough edges this game possesses. There was an exceptional game buried in here somewhere, but they oversimplified it away and buried it under hours and hours of non-interactive cutscenes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      